<template>
  <div class="workflows">
    <Notification/>
    <h1>Job Search</h1>
    <h2>{{this.workflow.name}}</h2>
    <div>
      <SearchBar @searchList="searchWorkflowJobs" @resetList="cancelJobsSearch" @change="populateJobsSearchTerm"/>
      <div id="job-update-container">
        <div v-if="this.jobsToManage.length===0" class="workflow-jobs-none"></div>
        <div v-if="this.workflowJobActions!==undefined && this.jobsToManage.length!==0" id="workflow-jobs-select">  
          <select id="workflow-jobs-action-filter"
                  name="workflow-jobs-action-filter"
                  class="form-control mt-1"
                  v-model="selectedWorkflowJobAction"
                  @change="selectWorkflowJobAction">
            <option value="" disabled selected>Job Actions</option>    
            <option v-for="wa in workflowJobActions" v-bind:value="wa" :key="wa">{{ wa }}</option>
          </select>
          <button type="button" class="app-button primary-button" v-on:click="updateWorkflowJobStatusAndRefresh">Go</button>
        </div>
      </div>
      <table>
        <thead>
          <tr id="results-tab">
            <td v-if="jobsLoading"><small><font-awesome-icon v-if="jobsLoading" :icon="['fas', 'spinner']"/></small></td>
            <td v-if="!jobsLoading && jobs!==undefined"><small>Displaying {{jobs!==undefined && jobs.length === 1? "1 Job" : jobs.length + " Jobs"}}</small></td>
          </tr>
          <tr>          
            <th>Step</th>
            <th>UI ID</th>
            <th>Status</th>
            <th>P2020 Status</th>
            <th>AWS Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <font-awesome-icon v-show="jobsLoading" :icon="['fas', 'spinner']" class="loading-component"/>
        <tbody v-if="jobs!==undefined">        
          <tr class="blank-row">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>      
          <td class="p2020-dual-buttons">
            <button v-if="!jobsLoading && jobs.length > 0" type="button" class="p2020-btn-small p2020-btn-clear" v-on:click="handleClearAllJobs()">Clear</button>
            <button v-if="!jobsLoading && jobs.length > 0" type="button" class="p2020-btn-small p2020-btn-all" v-on:click="handleSelectAllJobs()">All</button>
          </td>
          <td></td>
        </tr>  
          <tr v-for="wfj, index in jobs" :key="index">
            <td v-show="wfj.step!=undefined"><router-link target="_blank" :to="{ name: 'JobDetails', params: {workflowId: this.workflow.workflow_id, projectName: this.workflow.project, step: wfj.step, jobId: wfj.job_id }}">{{ wfj.step}}</router-link></td>           
            <td :class="{error: wfj.ui_id.includes('ERROR')}" v-show="wfj.ui_id!=undefined">{{ wfj.ui_id}}</td>
            <td>{{ wfj.status }}</td>
            <td>{{ wfj.p2020}}</td>
            <td>{{ wfj.aws}}</td>
            <td><input type="checkbox" @click="handleManageJob(wfj)" v-model="checkboxes[index]"/></td>
            <td v-if="!this.jobLoading(wfj.job_id)" id="refresh-job" @click="refreshRow({job:  wfj})"><img style="width:1.5rem;height:1.5rem;" :src=arrowsRotateIcon title="refresh data"/></td>
            <td v-if="this.jobLoading(wfj.job_id)"><img :src=spinner title="refresh data"/></td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmModal v-if="confirmingChoice" @confirm="handleConfirm" :message="this.warningMessage" :level="this.level" :command="this.command"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Notification from '../Generics/Notification.vue'
import SearchBar from '../Generics/SearchBar.vue'
import ConfirmModal from '../Generics/ConfirmModal.vue'

export default {
  name: 'WorkflowJobsSearch',
  components: {
    Notification,
    SearchBar,
    ConfirmModal
  },
  props: {
    workflowId: String,
    projectName: String,
    selectedWorkflowJobAction: String,
  },
  computed: {
    ...mapGetters({
      workflow: 'getWorkflow',
      jobs: 'getJobs', 
      workflowJobActions: 'getWorkflowJobActions',
      steps: 'getWorkflowSteps',
      jobsLoading: 'jobsLoading',
      jobsError: 'jobsError',
      workflow: 'getWorkflow',
      availableLimits: 'getAvailableLimits',
      limit: 'getLimit',
      jobsSearch: 'getJobsSearch',
      selectedWorkflowJobAction: 'getSelectedJobAction',
    }),
    confirmingChoice(){
      return this.confirming
    },
  },
  data(){
    return{
      checkboxes: [],
      workflowName: "",
      jobsToManage: [],
      selectedJob: "",
      jobToRefresh: "",
      level:"",
      command: "",
      confirming: false,
      arrowsRotateIcon: require('../../assets/arrows-rotate-solid.svg'),
      spinner: require('../../assets/spinner-solid.svg')
    }
  },
  methods: {
    ...mapActions([
      'loadWorkflow',
      'loadJobs', 
      'loadWorkflowJobActions', 
      'clearWorkflowJobActions',
      'updateWorkflowJobStatus',
      'updatejobstatus',
      'selectWorkflowJobAction', 
      'primeJobSearch',
      'Jobs',
      'clearJobsSearch',
      'populateJobsSearch',
      'searchJobsAcrossWorkflow',
      'refreshJobData',
      'setJobsLoad'
    ]),
    populateJobsSearchTerm(e){
      if(e!==null){
        this.populateJobsSearch(e.target.value)
      }
    },
    async refreshRow(jobRow){
      this.jobToRefresh = jobRow.job.job_id
      let job = {step: jobRow.job.step, id: jobRow.job.job_id}
      await this.refreshJobData(job)
      this.jobToRefresh = ""
      this.clearWorkflowJobsStatus()
    },
    jobLoading(job_id){
      return job_id===this.jobToRefresh
    },
    clearWorkflowJobsStatus(){
      this.selectedJob = "",
      this.selectingJobStatus = false
    },
    async searchWorkflowJobs(){
      await this.searchJobsAcrossWorkflow({workflowId: this.workflowId, projectName: this.projectName, step: this.step})
    },
    async cancelJobsSearch(){
      await this.clearJobsSearch()
    },
    handleSelectLimit(newLimit){
      this.setLimit(newLimit)
    },
    handleManageJob(job){
      let jobsToManageIncludesJob = this.jobsToManage.includes(job)
      if(!jobsToManageIncludesJob){
        this.jobsToManage.push(job)
      }
      else{
        this.jobsToManage = this.jobsToManage.filter(j => j !== job)
      }
      this.loadWorkflowJobActions(this.jobsToManage)
    },
    handleSelectAllJobs(){
      this.jobsToManage = []
      this.checkboxes = []
      for(let i = 0; i < this.jobs.length; i++){
          this.checkboxes[i] = true
          this.jobsToManage.push(this.jobs[i])
      }
      this.loadWorkflowJobActions(this.jobsToManage)
    },
    handleClearAllJobs(){
      this.jobsToManage = []
      this.checkboxes = []
      this.clearWorkflowJobActions()
    },
    async updateWorkflowJobStatusAndRefresh(){
      if(this.selectedWorkflowJobAction==="remove"){
        this.level="job"
        this.command=this.selectedWorkflowStepAction
        this.warningMessage='WARNING: the remove command WILL DELETE DATA created by each selected job. Please confirm or cancel action'
        this.confirming=true
      }
      else if(this.selectedWorkflowJobAction==="force_run"){
        this.level="job"
        this.command=this.selectedWorkflowStepAction
        this.warningMessage='WARNING: Each selected job WILL RUN WITH SOME INPUT DATA MISSING. Please confirm or cancel action'
        this.confirming=true
      }
      else{
        let jobArgs = {
          "project":  this.projectName, 
          "workflow_id": this.workflowId, 
          "jobs": this.jobsToManage.map(j => ({
            "step": j.step,
            "job_id": j.job_id
          }))
        }
        await this.updateWorkflowJobStatus(jobArgs); 
      }
    },
    async handleConfirm(result){
      if(result.response){
        let jobArgs = {
          "project":  this.projectName, 
          "workflow_id": this.workflowId, 
          "jobs": this.jobsToManage.map(j => ({
            "step": j.step,
            "job_id": j.job_id
          }))
        }
        await this.updateWorkflowJobStatus(jobArgs); 
      }
      this.confirming=false
    }
  },
  async mounted () {
    if(this.workflowId!==''){
      await this.loadWorkflow({workflowId: this.workflowId, projectName: this.projectName})
      this.workflowName = this.workflow.name
    }
    this.setJobsLoad()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error{
  border:0.2rem solid red;
}

.workflow-jobs-none{
  height:4rem;
}

#job-update-container{
  display: flex;
  justify-content: center;

}

#job-update-button{
  margin:1rem auto;
}

#workflow-jobs-action-filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

#workflow-jobs-select{
  justify-content: center;
  margin: 1rem auto;
  display:flex;
}

#workflow-jobs-select button{
  margin-left: 1rem;
}

.app-middle{
  margin:auto;
  text-align: center;
}

.step-select{
  margin:auto;
  text-align: center;
}

.step-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}
.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

select{
  margin:auto;
  width:10rem;
}

.status-select{
  margin:auto;
  text-align: center;
}
.status-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

#refresh-job{
  cursor: pointer;
  width:1rem;
}

</style>
